import React, { useCallback } from "react"
import { graphql, navigate, PageProps } from "gatsby"

import { getArrowIcon } from "assets/helper/icons"
import { useGeneralSettings } from "hooks/useGeneralSettings"
import { usePaginationSlug } from "hooks/usePaginationSlug"

import { Layout } from "components/layout"
import { SEO } from "components/seo/seo"
import { GlobalFooter } from "components/global-footer"

import { BlocksWrapper } from "components/blocks-wrapper/BlocksWrapper"
import { BlogList } from "components/blog"
import { BlogPageData, SingleArticle } from "components/blog/_types"
import { Breadcrumbs, BreadcrumbsGroup } from "components/breadcrumbs"
import { GenericHero } from "components/hero/GenericHero/GenericHero"
import { Pagination } from "components/pagination/Pagination"
import { StubBanner } from "components/form-stub/StubBanner"

import "assets/scss/4-templates/blog-page-template.module.scss"

type PageContext = PageProps["pageContext"] & {
  articles: SingleArticle[]
  breadcrumb: BreadcrumbsGroup
  category: string
  currentPage: number
  limit: number
  linkedPagesUrlPathCodex: Record<string, string>
  noIndex: boolean
  numBlogPages: number
  slug: string
}

type PageDataElement = {
  elements: BlogPageData
}

type PageData = {
  allKontentItemPage: {
    nodes: PageDataElement[]
  }
}
interface BlogPageProps extends PageProps {
  data: PageData
  pageContext: PageContext
}

const BlogPage: React.FC<BlogPageProps> = ({ data, location, pageContext }) => {
  const [page] = data.allKontentItemPage.nodes

  const {
    // seo_metadata__overlay_cta_text,
    banner,
    global_footer,
    seo_metadata__canonical_link,
    seo_metadata__meta_description,
    seo_metadata__meta_title,
    seo_metadata__rich_data_image,
  } = page.elements

  const {
    articles,
    breadcrumb,
    category,
    currentPage,
    limit,
    linkedPagesUrlPathCodex,
    noIndex,
    numBlogPages,
    slug,
  } = pageContext

  const { pathname } = location

  const { homeSlug, pageSlug, prevPage, nextPage } = usePaginationSlug({
    currentPage,
    isCategoryPath: !!category,
    slug,
  })

  const { globalFormStubBanner } = useGeneralSettings()

  const getCurrentPagePosts = () => {
    let postsList = [...articles]

    if (category) {
      let filteredPosts = [] as SingleArticle[]

      if (category) {
        filteredPosts = postsList.filter(
          post =>
            post?.node?.elements?.category?.value[0]?.elements.category_name
              .value === category
        )
      }

      postsList = [...filteredPosts]
    }

    const currentPagePosts = postsList.filter(
      (_, i) => i < currentPage * limit && i >= currentPage * limit - limit
    )

    return currentPagePosts
  }

  const onNextPage = useCallback(() => navigate(nextPage), [nextPage])
  const onPreviousPage = useCallback(() => navigate(prevPage), [prevPage])
  const onPageChange = useCallback(
    (pageNumber: number) =>
      navigate(pageNumber === 1 ? homeSlug : `${pageSlug}${pageNumber}`),
    [homeSlug, pageSlug]
  )

  const currentPageCards = getCurrentPagePosts()

  return (
    <Layout
      linkedPagesUrlPathCodex={linkedPagesUrlPathCodex}
      location={slug}
      pathName={pathname}
    >
      <SEO
        canonical={seo_metadata__canonical_link.value}
        description={seo_metadata__meta_description.value}
        image={seo_metadata__rich_data_image}
        noIndex={noIndex}
        title={seo_metadata__meta_title.value}
      />

      <GenericHero banner={banner.value[0].elements} />

      {globalFormStubBanner && Object.keys(globalFormStubBanner).length > 0 && (
        <StubBanner formStub={globalFormStubBanner} />
      )}

      {category && (
        <Breadcrumbs
          crumbLabel={category}
          crumbs={breadcrumb.crumbs.slice(0, 4)}
          hiddenCrumbs={["/blog/category", "/page-*"]}
        />
      )}

      <section className="fr-blog-page-template">
        {category && (
          <div className="fr-container u-container-header">
            <h2 className="fr-blog-page-template__heading">
              <span className="fr-blog-page-template__heading--regular">
                Articles Under
              </span>{" "}
              {getArrowIcon("right", "fr-blog-page-template__heading--divider")}{" "}
              <span>{category}</span>
            </h2>
          </div>
        )}

        {currentPageCards.length > 0 && <BlogList items={currentPageCards} />}

        {numBlogPages > 1 && (
          <div className="fr-container fr-blog-page-template__pagination-container">
            <Pagination
              currentPage={currentPage}
              onNextPage={onNextPage}
              onPageChange={onPageChange}
              onPreviousPage={onPreviousPage}
              pageSize={limit}
              totalCount={articles.length}
            />
          </div>
        )}
      </section>
      {global_footer && global_footer?.value?.length > 0 && (
        <GlobalFooter content={global_footer} />
      )}
    </Layout>
  )
}

export const getBlog = graphql`
  query getBlog($slug: String!) {
    allKontentItemPage(
      filter: { elements: { slug: { value: { eq: $slug } } } }
    ) {
      nodes {
        elements {
          banner {
            value {
              ...HeroBannerFragment
            }
          }
          global_footer {
            value {
              ...GlobalFooterFragment
            }
          }
          page_type {
            value {
              name
            }
          }
          seo_metadata__canonical_link {
            value
          }
          seo_metadata__meta_description {
            value
          }
          seo_metadata__meta_title {
            value
          }
          seo_metadata__overlay_cta_text {
            value
          }
          seo_metadata__rich_data_image {
            value {
              url
            }
          }
          slug {
            value
          }
        }
      }
    }
  }
`
export default BlogPage
